import { MutationKey } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';
import { IdentityId, UnboundIdentity } from '@playq/services-fingerprint';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export type UnbindType = {
  identity: IdentityId;
  env: Environment;
};

export const unbindMutationKey: MutationKey = ['playerlookup', 'mutation', 'unbindUser'];

export const useUnbind = (options?: UseMutationOptionsExtended<UnboundIdentity, GenericFailure | Error, UnbindType>) =>
  useEitherMutation(({ env, identity }) => services2.playerLookupService.unbindUser(env, identity), {
    onError: (err) => snackbarService.genericFailure(err.message),
    mutationKey: unbindMutationKey,
    ...options,
  });
