import { MutationKey } from '@tanstack/react-query';

import { GenericFailure, UserId } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';
import { DeletedIdentity } from '@playq/services-fingerprint';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export type DestroyUserDataOnAllServicesAllAppsType = {
  env: Environment;
  userId: UserId;
};
export const destroyUserDataOnAllServicesAllAppsMutationKey: MutationKey = [
  'playerlookup',
  'mutation',
  'destroyUserDataOnAllServicesAllApps',
];

export const useDestroyUserDataOnAllServicesAllApps = (
  options?: UseMutationOptionsExtended<DeletedIdentity, GenericFailure | Error, DestroyUserDataOnAllServicesAllAppsType>
) =>
  useEitherMutation(
    ({ env, userId }) => services2.playerLookupService.destroyUserDataOnAllServicesAllApps(env, userId),
    {
      onError: (err) => snackbarService.genericFailure(err.message),
      mutationKey: destroyUserDataOnAllServicesAllAppsMutationKey,
      ...options,
    }
  );
